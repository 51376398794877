<template>
  <div
    class="mx-6"
    v-html="disclaimer"
  />
</template>
<script>
export default {
  props: {
    disclaimer: {
      type: String,
      default: ''
    }
  }
}
</script>
